














































































































































































































































































































.container{
  max-width: 810px;
}
  .header-row .container{
    background: #4472c4;
    padding:30px;
    h1{
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .star-container {
    font-size: 15px;
    background: #538df4;
    border-radius:20px;
    align-items: center;
    padding: 0 3px 8px 12px;
    margin-right: 10px;
    .rating-count{
      margin-bottom: -9px;
      font-weight: bold;
      margin-right: 5px;
    }
    .star-count {
      display: inline-block;
      unicode-bidi: bidi-override;
      color: #888888;
      font-size: 23px !important;
      span {
        font-size: 23px !important;
        &:last-child{
          padding-right: 0;
        }
      }

      height: 25px;
      width: auto;
      margin: 0;
      position: relative;
      padding: 0;

      .rating-upper {
        color: #fff;
        padding: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      .rating-lower {
        padding: 0;
        display: flex;
        z-index: 0;
      }
    }
  }
  .review-count{
    font-weight: bold;
    font-size: 15px;
  }
  main{
    h2{
      color: #3b3c3c;
    }
    .main-text{
      max-width:570px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      color: #7b7c7b;
    }
    .thumbs{
      span{
        color: #7b7c7b;
      }
      > .d-flex{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        span{
          max-width: 300px;
          word-break: break-all;
        }
      }
      .thumbs-up{
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
      .thumbs-down{
        flex: 1;
        display: flex;
        button{
          appearance: none;
          background:none;
          border:0;
          font-weight: bold;
          span{
            max-width: 300px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .footer-row{
    background: #d9d9d9;
    text-align: center;
    padding: 20px;
    color: #8d8d8d;
    font-weight: bold;
  }
  .neg-feedback {
    .modal-content{
      border-radius: 0;
      border: 0;
    }
    .feedback{
      text-align: center;
      margin-top: 10px;
      color: green;
    }
    .skip-gate {
      color: #7b7b7b;
      background: #d9d9d8;
      text-align: center;
      padding: 5px 10px;
      margin: 30px -16px -16px -16px;
      font-size: 14px;
      border-bottom: 2px solid #8a8a8b;

      a {
        color: #538ef4;
      }
    }
  }
  @media screen and (max-width:640px){
    .header-row .container {
      padding: 15px;
      h1 {
        font-size: 18px;
      }
      .star-container{
        font-size: 13px;
        .star-count{
          font-size: 18px !important;
          height: 20px;
          span{
            font-size: 18px !important;
          }
        }
      }
      .review-count{
        padding-top: 2px;
        font-size: 13px;
      }
    }
  main {
    h2{
      font-size: 22px;
    }
    .main-text{
      font-size: 14px;
    }
    .thumbs{
      span{
        font-size: 12px;
      }
    }
    .footer-row{
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 12px;
      padding: 10px 0;
      font-size: 10px;
      .col{
        padding: 0 5px;
      }
    }
  }
}
@media screen and (max-width:400px){
  main{
    .thumbs{
      .thumbs-up{
        justify-content: flex-start;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .thumbs-down{
        justify-content: flex-end;
        padding-right: 0 !important;
        padding-left: 0 !important;

      }
    }
  }
}
